import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import QueryBuilder from "../../components/QueryBuilder/QueryBuilder";
import {
  createSegment,
  getSegmentById,
  SegmentType,
  updateSegment,
} from "../../services/segments";
import {
  Customer,
  getGroups,
  getCustomersByRuleGroups,
  Group,
  addCustomerToGroup,
  addCustomerNote,
  addCustomerTag,
  pullCustomersFromOffset,
  updateCustomer,
  removeFromGroups,
  getCustomerById,
  filterAddCustomersGroup,
  filterMoveCustomersGroup,
  filterAddCustomersNote,
  filterAddCustomersTag,
} from "../../services/customers";
import {
  Button,
  Text,
  Image,
  Input,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useSpring, animated } from '@react-spring/web';
import { useHistory, useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { FaRobot, FaRegThumbsUp, FaRegThumbsDown, FaSort, FaSortUp, FaSortDown, FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";
import { RuleRowType } from "../../components/QueryBuilder/RuleRow";
import { CheckboxStyled, CheckboxNormal } from "../../components/Checkbox/Checkbox";
import csvDownload from "json-to-csv-export";
import { formatCurrency, getUploadRuleGroups } from "../../utils/formatters";
import { useToken } from "../../services/auth";
import { EntitiesEnum, OptionType } from "../../services/queryBuilder";
import { query, getRuleGroupValueFromNL, QueryResult, Expression, doNLEvaluate, doNLFeedback, getQueryLogicCode } from "../../services/nlQuery";
import NavBar from "../../components/NavBar/NavBar";
import {
  PAGE_INDEX_SEGMENTS, 
} from "../../utils/constants";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  customerIds: string[];
  clearAllBefore?: boolean;
  totalChecked?: boolean;
  total?: number;
  accountId?: string,
  ruleGroups?: RuleRowType[][];
};

type EditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  customer: Customer | undefined;
};

type NLModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onNLQueryCommit: (queryId: string, words: string, querySegment: SegmentType | null, errorMessage: string | null) => void;
  segment: SegmentType | undefined;
  defaultModel: string;
  defaultShow: boolean;
  nlWords: string;
};

type CustomerSegmentProps = {
  signOut: () => void;
  setPageIndex: (pageIndex: number) => void;
};

const AddToGroupModal = ({
  isOpen,
  onClose,
  customerIds,
  clearAllBefore,
  totalChecked,
  total,
  accountId,
  ruleGroups,
}: ModalProps) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(
    undefined
  );
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupOptions, setGroupOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const onAddGroup = async () => {
    if (!selectedGroupId) {
      return;
    }

    try {
      setIsLoading(true);

      if(totalChecked && total && accountId && ruleGroups){
        if(clearAllBefore){
          await filterMoveCustomersGroup(accountId, selectedGroupId, ruleGroups);
        }else{
          await filterAddCustomersGroup(accountId, selectedGroupId, ruleGroups);
        }
      }else{
        if (clearAllBefore) {
          const clearGroupPromises = customerIds.map((customerId) => {
            return removeFromGroups(customerId);
          });
          await Promise.all(clearGroupPromises);
        }
  
        const updatePromises = customerIds.map((customerId) => {
          return addCustomerToGroup(customerId, selectedGroupId);
        });
        await Promise.all(updatePromises);
      }
      
      toast({
        title: clearAllBefore
          ? "Customers moved to group!"
          : "Customers added to group!",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message.length > 0 ? (error as Error).message : (clearAllBefore
          ? "Move customers to group failed!"
          : "Add customers to group failed!"),
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const loadGroups = async () => {
    try {
      setIsLoading(true);
      const groups = await getGroups();
      setGroups(groups);
      var options: OptionType[] = []
      groups.map((group) => {
        options.push({value: group.id, label: group.name})
      })
      setGroupOptions(options)
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {clearAllBefore ? "Move to Group" : "Add to Group"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            value={groupOptions?.find(
              (c) => parseInt(c.value, 10) === selectedGroupId
            )}
            options={groupOptions?.map(
              (option) => ({
                value: option.value,
                label: option.label,
              })
            )}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px',
                minHeight: '40px',
              }),
              option: (styles, {isSelected, isFocused}) => ({
                ...styles,
                backgroundColor: isSelected ? '#FFB9AC' : isFocused ? '#E7EAEC' : 'white',
                color: '#2A2F56',
                fontSize: '14px',
                paddingLeft: '24px',
              }),
            }}
            onChange={(e) => {
              if(e?.value){
                setSelectedGroupId(parseInt(e?.value, 10));
              }
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onAddGroup} isLoading={isLoading} variant="offset">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AddNotesModal = ({ 
  isOpen, 
  onClose, 
  customerIds,
  totalChecked,
  total,
  accountId,
  ruleGroups,
}: ModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const { userInfo } = useToken();
  const toast = useToast();

  const onAddNote = async () => {
    if (!userInfo) {
      return;
    }

    try {
      setIsLoading(true);

      if(totalChecked && total && accountId && ruleGroups){
        await filterAddCustomersNote(accountId, note, userInfo, ruleGroups);
      }else{
        const updatePromises = customerIds.map((customerId) => {
          return addCustomerNote(customerId, note, userInfo);
        });

        await Promise.all(updatePromises);
      }

      toast({
        title: "Note added to customers!",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message.length > 0 ? (error as Error).message : "Add note to customers failed!",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Notes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            variant={"offset"}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Notes"
            type={"text"}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onAddNote} isLoading={isLoading} variant="offset">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AddTagsModal = ({ 
  isOpen, 
  onClose, 
  customerIds,
  totalChecked,
  total,
  accountId,
  ruleGroups,
}: ModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tag, setTag] = useState<string>("");
  const toast = useToast();

  const onAddTag = async () => {
    try {
      setIsLoading(true);

      if(totalChecked && total && accountId && ruleGroups){
        await filterAddCustomersTag(accountId, tag, ruleGroups);
      }else{
        const updatePromises = customerIds.map((customerId) => {
          return addCustomerTag(customerId, tag);
        });

        await Promise.all(updatePromises);
      }

      toast({
        title: "Tag added to customers!",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message.length > 0 ? (error as Error).message : "Add tag to customers failed!",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            onChange={(e) => setTag(e.target.value)}
            placeholder="Tag"
            type={"text"}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onAddTag} isLoading={isLoading} variant="offset">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const NLQueryModal = ({ isOpen, onClose, onNLQueryCommit, segment, defaultModel, defaultShow, nlWords }: NLModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputWords, setInputWords] = useState<string>(nlWords);
  const models = [{label: "GPT-3.5 Fastest model. Great for everyday queries.", value: "3"}, {label: "GPT-4.5 Most capable. Good for advanced queries.", value: "4"}]
  const [selectedModel, setSelectedModel] = useState<string>(defaultModel);
  const [needShowQuestion, setNeedShowQuestion] = useState<boolean>(defaultShow);
  const { userInfo } = useToken();
  const toast = useToast();

  const onCommitNLQuery = async () => {
    if(!selectedModel){
      toast({
        title: "Please select GPT Model.",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
      return;
    }
    if(!inputWords){
      toast({
        title: "Please input natural language.",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
      return;
    }
    try {
      setIsLoading(true);
      localStorage.setItem("model", selectedModel)
      localStorage.setItem("need_show_question", needShowQuestion ? "true" : "false")

      const queryData = await query(EntitiesEnum.CUSTOMERS, inputWords, selectedModel, needShowQuestion, "", userInfo?.currentAccount?.id);
      if(queryData.result_text){
        var queryResult: QueryResult
        if(queryData.result_text.includes("\"expression\"") || queryData.result_text.includes("\"error_message\"")){
          queryResult = JSON.parse(queryData.result_text) as QueryResult;
        }else{
          queryResult = {} as QueryResult
          queryResult.expression = JSON.parse(queryData.result_text) as Expression;
        }
        if(queryResult.expression){
          var querySegment: SegmentType
          if(segment){
            querySegment = segment
          }else{
            querySegment = {
              id: "",
              name: "",
              ruleGroups: [],
              entity_type: EntitiesEnum.CUSTOMERS,
              not_purchased: 0
            }
          }
  
          querySegment.ruleGroups.splice(0, segment?.ruleGroups.length)
          getRuleGroupValueFromNL(
            queryResult.expression, 
            [
              EntitiesEnum.GROUPS, 
              EntitiesEnum.CUSTOMERS,
              EntitiesEnum.ORDERS,
              EntitiesEnum.PRODUCTS,
          ]).forEach((e) =>{
            querySegment.ruleGroups.push(e)
          })
          onNLQueryCommit(queryData.id.toString(), inputWords, querySegment, "");
        }else if(queryResult.error_message){
          onNLQueryCommit(queryData.id.toString(), inputWords, null, queryResult.error_message)
        }else{
          toast({
            title: "Natural Language Query failed.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: false,
            });
        }
      }else{
        toast({
          title: "Natural Language Query failed.",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: false,
          });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Natural Language Query failed.",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Natural Language Query</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            placeholder="Select GPT Model..."
            value={models?.find(
              (c) => c.value === selectedModel
            )}
            options={models?.map(
              (model) => ({
                value: model.value,
                label: model.label,
              })
            )}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px',
                minHeight: '40px',
              }),
              option: (styles, {isSelected, isFocused}) => ({
                ...styles,
                backgroundColor: isSelected ? '#FFB9AC' : isFocused ? '#E7EAEC' : 'white',
                color: '#2A2F56',
                fontSize: '14px',
                paddingLeft: '24px',
              }),
            }}
            onChange={(e) => {
              if(e?.value){
                setSelectedModel(e?.value);
              }
            }}
          />
          <Textarea
            multiple
            mt={"20px"}
            height={"100px"}
            fontSize={"14px"}
            borderColor={"#cccccc"}
            onChange={(e) => setInputWords(e.target.value)}
            placeholder="Use natural language to search customers"
            type={"text"}
            value={inputWords}
          />
          {/* <CheckboxNormal
            isChecked={needShowQuestion}
            onChange={() => setNeedShowQuestion(!needShowQuestion)}
            mt={"20px"}
          >
            Show question to clarify
          </CheckboxNormal> */}
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onCommitNLQuery} isLoading={isLoading} variant="offset">
            Commit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const EditCustomerModal = ({ isOpen, onClose, customer }: EditModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstName, setName] = useState<string>(customer?.first_name || "");
  const [email, setEmail] = useState<string>(customer?.email || "");
  const [phone, setPhone] = useState<string>(customer?.phone || "");
  const [address, setAddress] = useState<string>(customer?.address || "");

  const toast = useToast();

  const onEditCustomer = async () => {
    try {
      setIsLoading(true);
      await updateCustomer({
        id: customer?.id.toString(),
        remote_id: customer?.remote_id.toString(),
        first_name: firstName,
        email,
        phone,
        address,
      });
      toast({
        title: "Customer updated!",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Customer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            type={"text"}
            value={firstName}
          />
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type={"text"}
            value={email}
          />
          <Input
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
            type={"text"}
            value={phone}
          />
          <Input
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
            type={"text"}
            value={address}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={onEditCustomer}
            isLoading={isLoading}
            variant="offset"
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CustomerInfoModal = ({ isOpen, onClose, customer }: EditModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [offsetCustomer, setOffsetCustomer] = useState<Customer | undefined>(
    undefined
  );

  const loadCustomer = useCallback(async () => {
    if (!customer) {
      return;
    }

    try {
      setIsLoading(true);
      const originalCustomer = await getCustomerById(customer.id);
      setOffsetCustomer(originalCustomer);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [customer]);

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Customer Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner color="orange.100" />
          ) : (
            <Flex direction={"column"} gridGap={4}>
              <Flex gridGap={4}>
                <Box fontWeight={"bold"}>Name:</Box>
                <Box>
                  {offsetCustomer?.first_name + " " + offsetCustomer?.last_name}
                </Box>
              </Flex>
              <Flex gridGap={4}>
                <Box fontWeight={"bold"}>Email:</Box>
                <Box>{offsetCustomer?.email}</Box>
              </Flex>
              {/* <code>{JSON.parse(JSON.stringify(groups))}</code> */}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Segment = ({signOut, setPageIndex}: CustomerSegmentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFirstQuery, setIsFirstQuery] = useState<boolean>(true);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [segment, setSegment] = useState<SegmentType | undefined>(undefined);
  const [input, setInput] = useState<string>("");
  const [defaultModel, setDefaultModel] = useState<string>("");
  const [defaultShow, setDefaultShow] = useState<boolean>(false)
  const [nlWords, setNLWords] = useState<string>("");
  const [nlQueryId, setNLQueryId] = useState<string>("");
  const [nlEvaluate, setNLEvaluate] = useState<number>(-1);
  const [errorWords, setErrorWords] = useState<string>("")
  const [checkedCustomers, setCheckedCustomers] = useState<string[]>([]);
  const [selecetedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined);
  const [width, setWidth] = useState<number>(0);
  const [showBottomBar, setShowBottomBar] = useState<boolean>(checkedCustomers.length > 0);
  const [totalChecked, setTotalChecked] = useState<boolean>(false);
  const [selTableIndex, setSelTableIndex] = useState<number>(0);
  const [sortType, setSortType] = useState<number>(0);
  const [pageSizeChanges, setPageSizeChanges] = useState<boolean>(false);
  const [pageChangesAndSelectAll, setPageChangesAndSelectAll] = useState<boolean>(false);

  const actions = [
    {label: "Choose an Action...", value: "0"}, 
    {label: "Add to Group", value: "1"}, 
    {label: "Move to Group", value: "2"}, 
    {label:  "Add Notes", value: "3"}, 
    {label: "Add Tags", value: "4"}, 
    {label: "Edit Customer", value: "5", isDisabled: checkedCustomers.length !== 1 || totalChecked}
  ]
  const [selectedAction, setSelectedAction] = useState<string>(actions[0].value);

  const [page, setPage] = useState({
    total: 0,
    pageSize: 50,
    pageIndex: 1,
  });

  const { userInfo, setToken } = useToken();

  // Add to group modal
  const {
    isOpen: isAddToGroupOpen,
    onOpen: onAddToGroupOpen,
    onClose: onAddToGroupClose,
  } = useDisclosure();
  // Add notes modal
  const {
    isOpen: isAddNotesOpen,
    onOpen: onAddNotesOpen,
    onClose: onAddNotesClose,
  } = useDisclosure();
  // Add tags modal
  const {
    isOpen: isAddTagsOpen,
    onOpen: onAddTagsOpen,
    onClose: onAddTagsClose,
  } = useDisclosure();
  // Edit customer modal
  const {
    isOpen: isEditCustomerOpen,
    onOpen: onEditCustomerOpen,
    onClose: onEditCustomerClose,
  } = useDisclosure();
  // Add to group modal
  const {
    isOpen: isMoveToGroupOpen,
    onOpen: onMoveToGroupOpen,
    onClose: onMoveToGroupClose,
  } = useDisclosure();
  // Customer information modal
  const {
    isOpen: isCustomerInfoOpen,
    onOpen: onCustomerInfoOpen,
    onClose: onCustomerInfoClose,
  } = useDisclosure();
  // Natural Language Query modal
  const {
    isOpen: isNLQueryOpen,
    onOpen: onNLQueryOpen,
    onClose: onNLQueryClose,
  } = useDisclosure();

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();

  const allChecked = useMemo(
    () =>
      checkedCustomers.length >= customers.length &&
      customers.every((item) => checkedCustomers.includes(item.remote_id)),
    [checkedCustomers, customers]
  );
  const isIndeterminate = useMemo(
    () =>
      checkedCustomers.length > 0 &&
      !allChecked &&
      customers.some((item) => checkedCustomers.includes(item.remote_id)),
    [checkedCustomers, allChecked, customers]
  );

  const judgeExpired = () => {
    var isExpired = true;
    var loginTimeStr = localStorage.getItem("login_time");
    if(loginTimeStr && loginTimeStr.length > 0){
      var loginTime = Number.parseInt(loginTimeStr);
      var currentTime = new Date().getTime();
      isExpired = currentTime - loginTime > 3 * 24 * 3600 * 1000;
    }
    if(isExpired){
      signOut();
    }

    return isExpired;
  }

  const getFloatValue = (valueStr: string) => {
    if(valueStr && valueStr.length > 0){
      return Number.parseFloat(valueStr);
    }

    return 0;
  }

  const getStringLowerValue = (valueStr: string) => {
    if(valueStr && valueStr.length > 0){
      return valueStr.toLowerCase();
    }

    return "";
  }

  const handleTableSort = (columnIndex: number) => {
    var currentSortType = sortType;
    if(columnIndex == selTableIndex){
      currentSortType++;
      if(currentSortType > 3){
        currentSortType = 0;
      }
    }else{
      setSelTableIndex(columnIndex);
      currentSortType = 0;
    }
    setSortType(currentSortType);
    if(currentSortType <= 1){
      doSort(customers, columnIndex, currentSortType);
    }else if(segment){
      setCheckedCustomers([]);
      setTotalChecked(false);
      if(page.pageIndex == 1){
        loadCustomers(segment.ruleGroups, columnIndex, currentSortType)
      }else{
        setPage({
          ...page,
          pageIndex: 1,
        });
      }
    }
  };

  const doSort = (customers: Customer[], columnIndex: number, currentSortType: number) =>{
    switch(columnIndex){
      case 0:
        if(currentSortType == 0){
          customers.sort((a, b) => Number.parseInt(a.remote_id) - Number.parseInt(b.remote_id));
        }else{
          customers.sort((a, b) => Number.parseInt(b.remote_id) - Number.parseInt(a.remote_id));
        }
        break;
      case 1:
        if(currentSortType == 0){
          customers.sort((a, b) => getStringLowerValue(a.status) > getStringLowerValue(b.status) ? 1 : -1);
        }else{
          customers.sort((a, b) => getStringLowerValue(a.status) < getStringLowerValue(b.status) ? 1 : -1);
        }
        break;
      case 2:
        if(currentSortType == 0){
          customers.sort((a, b) => new Date(a.created_date ? a.created_date : a.created) > 
            new Date(b.created_date ? b.created_date : b.created) ? 1 : -1);
        }else{
          customers.sort((a, b) => new Date(a.created_date ? a.created_date : a.created) <
            new Date(b.created_date ? b.created_date : b.created) ? 1 : -1);
        }
        break;
      case 3:
        if(currentSortType == 0){
          customers.sort((a, b) => getStringLowerValue(a.first_name + " " + a.last_name) > getStringLowerValue(b.first_name + " " + b.last_name) ? 1 : -1);
        }else{
          customers.sort((a, b) => getStringLowerValue(a.first_name + " " + a.last_name) < getStringLowerValue(b.first_name + " " + b.last_name) ? 1 : -1);
        }
        break;
      case 4:
        if(currentSortType == 0){
          customers.sort((a, b) => getStringLowerValue(a.email + " " + a.phone) > getStringLowerValue(b.email + " " + b.phone) ? 1 : -1);
        }else{
          customers.sort((a, b) => getStringLowerValue(a.email + " " + a.phone) < getStringLowerValue(b.email + " " + b.phone) ? 1 : -1);
        }
        break;
      case 5:
        if(currentSortType == 0){
          customers.sort((a, b) => getStringLowerValue(a.customer_source) > getStringLowerValue(b.customer_source) ? 1 : -1);
        }else{
          customers.sort((a, b) => getStringLowerValue(a.customer_source) < getStringLowerValue(b.customer_source) ? 1 : -1);
        }
        break;
      case 6:
        if(currentSortType == 0){
          customers.sort((a, b) => getStringLowerValue(a.referral_source) > getStringLowerValue(b.referral_source) ? 1 : -1);
        }else{
          customers.sort((a, b) => getStringLowerValue(a.referral_source) < getStringLowerValue(b.referral_source) ? 1 : -1);
        }
        break;
      case 7:
        if(currentSortType == 0){
          customers.sort((a, b) => getFloatValue(a.order_total) - getFloatValue(b.order_total));
        }else{
          customers.sort((a, b) => getFloatValue(b.order_total) - getFloatValue(a.order_total));
        }
        break;
      case 8:
        if(currentSortType == 0){
          customers.sort((a, b) => getFloatValue(a.credits) - getFloatValue(b.credits));
        }else{
          customers.sort((a, b) => getFloatValue(b.credits) - getFloatValue(a.credits));
        }
        break;
    }
  }

  const onNLQueryCommit = (queryId: string, words: string, querySegment: SegmentType | null, errorMessage: string | null) => {
    setNLEvaluate(-1);
    setNLQueryId(queryId);
    if(querySegment){
      handleQueryUpdate(querySegment);
      setNLWords(words);
      setErrorWords("");
      if(isFirstQuery){
        setIsFirstQuery(false);
        if(segment){
          handleExecuteQuery();
        }
      }
    }else if(errorMessage){
      setNLWords(words);
      setErrorWords(errorMessage);
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(true);
    setInput(e.target.value);
  };

  const handleQueryUpdate = async (newSegment: SegmentType) => {
    if (segment) {
      setSegment({ ...segment, ruleGroups: newSegment.ruleGroups });
    } else {
      setSegment(newSegment);
    }
  };

  const handleExecuteQuery = async () => {
    if (!userInfo?.currentAccount) {
      return;
    }

    try {
      setIsTableLoading(true);
      var needSlideOut = checkedCustomers.length > 0;
      setCheckedCustomers([]);
      if(needSlideOut){
        slideOutAnim();
      }

      if (!segment || segment.id === "") {
        const newSegment = await createSegment(
          {
            name: input,
            id: "",
            ruleGroups: segment?.ruleGroups || [],
            entity_type: EntitiesEnum.CUSTOMERS,
            not_purchased: 0,
            account_id: userInfo.currentAccount.id,
          },
          userInfo.currentAccount.id
        );
        setSegment(newSegment);
        toast({
          title: "Segment created!",
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: false,
          });
        history.push(`/segs/customer/${newSegment.id}`);
      } else {
        const updatedSegment = {
          ...segment,
          name: input,
          account_id: userInfo.currentAccount.id,
        };
        await updateSegment(updatedSegment);
        setSelTableIndex(0);
        setSortType(0);
        if(page.pageIndex == 1){
          await loadCustomers(updatedSegment.ruleGroups, 0, 0);
        }else{
          setPage({
            ...page,
            pageIndex: 1,
          });
        }
        setSegment(updatedSegment);
        toast({
          title: "Segment updated!",
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: false,
          });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(pageChangesAndSelectAll){
      setPageChangesAndSelectAll(false);
      toggleAllCheckbox();
      setTotalChecked(true);
    }
  }, [customers]);

  const loadCustomers = useCallback(
    async (ruleGroups: RuleRowType[][], sortIndex: number, sortType: number) => {
      try {
        if (!userInfo?.currentAccount) {
          throw new Error("No account id");
        }
        if (judgeExpired()) {
          return;
        }

        setIsTableLoading(true);
        setTotalChecked(false);
        const { pageIndex, pageSize } = page;
        const pageObject = {
          pageIndex,
          pageSize,
        };
        const { data, total } = await getCustomersByRuleGroups(
          getUploadRuleGroups(ruleGroups),
          userInfo?.currentAccount?.id,
          pageObject,
          sortType > 1 ? sortIndex : 0,
          sortType > 1 ? sortType - 2 : 0,
        );
        if(sortType <= 1){
          doSort(data, sortIndex, sortType);
        }
        setCustomers(data);
        setPage({
          ...page,
          total,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsTableLoading(false);
      }
    },
    [userInfo?.currentAccount?.id, page.pageIndex, page.pageSize]
  );

  const loadSegment = useCallback(async () => {
    if (id === "new") {
      setIsTableLoading(false);
      return;
    }
    if (judgeExpired()) {
      return;
    }

    try {
      if(!segment){
        setIsLoading(true);
        const onlineSegment = await getSegmentById(id);
        setSegment(onlineSegment);
        setInput(onlineSegment.name);
        setIsLoading(false);
        await loadCustomers(onlineSegment.ruleGroups, 0, 0);
      }else if(pageSizeChanges){
        setPageSizeChanges(false);
        setPage({
          ...page,
          pageIndex: 1,
        });
      }else{
        await loadCustomers(segment.ruleGroups, selTableIndex, sortType);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [id, loadCustomers]);

  const remoteIds: string[] = useMemo(() => {
    return customers.map((customer) => customer.remote_id);
  }, [customers]);

  const toggleAllCheckbox = () => {
    if (allChecked) {
      const temp = new Set(checkedCustomers);
      remoteIds.map((item) => temp.delete(item));
      setCheckedCustomers(Array.from(temp));
      setTotalChecked(false);

      slideOutAnim();
    } else {
      var needSlideIn = checkedCustomers.length <= 0 && !showBottomBar
      const temp = new Set(remoteIds);
      setCheckedCustomers(Array.from(temp));

      if(needSlideIn){
        slideInAnim();
      }
    }
  };

  const toggleCheckbox = (customerId: string) => {
    var needSlideIn = false;
    var needSlideOut = false;
    const isPreviouslyChecked = checkedCustomers.includes(customerId);
    if (isPreviouslyChecked) {
      if(checkedCustomers.length == 1){
        needSlideOut = true;
      }
      setCheckedCustomers((prev) => prev.filter((id) => id !== customerId));
      setTotalChecked(false);
    } else {
      if(checkedCustomers.length == 0){
        needSlideIn = true;
      }
      setCheckedCustomers((prev) => [...prev, customerId]);
    }

    if(needSlideIn){
      slideInAnim();
    }else if(needSlideOut){
      slideOutAnim();
    }
  };

  const toggleTotalCheckbox = () => {
    setTotalChecked(!totalChecked);
  };

  const handleAddToGroup = () => {
    onAddToGroupOpen();
  };

  const handleAddNotes = () => {
    onAddNotesOpen();
  };

  const handleAddTags = () => {
    onAddTagsOpen();
  };

  const handleMoveToGroup = () => {
    onMoveToGroupOpen();
  };

  const handleNLQuery = () => {
    var localModel = localStorage.getItem("model")
    var localShow = localStorage.getItem("need_show_question") == "true"
    setDefaultModel(localModel ? localModel : "")
    setDefaultShow(localShow ? localShow : false)

    onNLQueryOpen();
  };

  const handleNLEvaluateUp = async () => {
    await doNLEvaluate(nlQueryId, 1);
    setNLEvaluate(1);
    toast({
      title: "Send evaluate success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleNLEvaluateDown = async () => {
    await doNLEvaluate(nlQueryId, 0);
    setNLEvaluate(0);
    toast({
      title: "Send evaluate success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleQueryFeedback = async () => {
    var logicCode = "";
    if(segment?.ruleGroups){
      logicCode = getQueryLogicCode(segment?.ruleGroups);
    }
    await doNLFeedback(nlQueryId, logicCode);
    setNLEvaluate(-1);
    toast({
      title: "Send feedback success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleViewCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
    onCustomerInfoOpen();
  };

  const updateFromSource = async () => {
    try {
      // setIsLoading(true);
      await pullCustomersFromOffset();

      if (segment) {
        setSelTableIndex(0);
        setSortType(0);
        await loadCustomers(segment.ruleGroups, 0, 0);
      }

      toast({
        title: "Customers updated!",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnEdit = async () => {
    onEditCustomerOpen();
  };

  const handleEditCustomerClose = async () => {
    onEditCustomerClose();
    await updateFromSource();
  };

  const onShowSizeChange = (current: number, pageSize: number): void => {
    if(current > 1){
      setPageSizeChanges(true);
    }
    setPage({
      ...page,
      pageIndex: current,
      pageSize,
    });
  };

  const onPageChange = (current: number, pageSize: number): void => {
    if(totalChecked){
      setPageChangesAndSelectAll(true);
    }
    setPage({
      ...page,
      pageIndex: current,
      pageSize,
    });
    var needSlideOut = checkedCustomers.length > 0 && !totalChecked;
    setCheckedCustomers([]);
    if(needSlideOut){
      slideOutAnim();
    }
  };

  const handleCsvDownload = useCallback(async () => {
    // const { currentAccountId } = userInfo;
    // const { ruleGroups } = segment;
    if (!(segment || userInfo)) return;
    const { data } = await getCustomersByRuleGroups(
      segment!.ruleGroups,
      userInfo?.currentAccount?.id,
      undefined,
      0,
      0,
      true
    );
    csvDownload({
      data: data,
      filename: `customers_${new Date().toISOString()}`,
    });
  }, [segment, userInfo]);

  const resizeUpdate = () => {
    let w = window.innerWidth;
    setWidth(w);
  }

  useEffect(() => {
    setPageIndex(PAGE_INDEX_SEGMENTS);
    loadSegment();
  }, [loadSegment]);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', resizeUpdate);
    return () =>{
      window.removeEventListener('resize', resizeUpdate);
    }
  })

  const [springs, api] = useSpring(() => ({
    from: { y: checkedCustomers.length > 0 ? -80 : 80 },
    config: { duration: 400},
  }))

  const slideInAnim = () => {
    setShowBottomBar(true);
    api.start({
      from: {
        y: 80,
      },
      to: {
        y: -80,
      },
    });
  }

  const slideOutAnim = () => {
    api.start({
      from: {
        y: -80,
      },
      to: {
        y: 80,
      },
      onRest: () => {
        setShowBottomBar(false);
      },
    });
  }

  return (
    <Flex direction={"column"} width={width - 260} height={"100%"}>
      <Flex direction={"column"} height={window.innerHeight - 24} overflow="auto">
        <NavBar removeToken={() => signOut()} entityType={EntitiesEnum.CUSTOMERS} paramName={"segs"}/>
        <Flex direction={"column"} alignItems={"start"} height={"100%"} backgroundColor={"white"} borderRadius={"4px"}>
          {isLoading ? (
            <Flex
              h={"300px"}
              w={"100%"}
              mx={"24px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Spinner color="orange.100" />
            </Flex>
          ) : (
            <Box w={"100%"} mt={4}>
              <Flex>
                {(isEditing || !input) ? (
                  <Flex 
                    alignItems={"center"} 
                    width={"50%"} 
                    height={"44px"}
                    justifyContent={"end"}
                    borderColor={"DFE2E6"}
                    borderWidth={"1px"}
                    borderRadius={"4px"}
                    mx={"24px"}
                  >
                    <Input
                      autoFocus
                      onChange={handleNameChange}
                      placeholder="Name..."
                      fontSize={"14px"}
                      borderColor={"white"}
                      color={"#2a2f56"}
                      value={input}
                      ml={"2px"}
                      mt={"8px"}
                      pr={"12px"}
                      height={"40px"}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEditing(false);
                          handleExecuteQuery();
                        }
                      }}
                    />
                    <Image
                      src="/ic_enter.png"
                      width={"12px"}
                      mr={"15px"}
                    />
                    <Image
                      src="/ic_corner.png"
                      width={"8px"}
                      mr={"3px"}
                      mt={"18px"}/>
                  </Flex>
                ):(
                  <Flex width={"70%"}>
                    <Flex 
                      alignItems={"center"}
                      justifyContent={"end"}
                      width={"wrap"}
                      height={"48px"}
                      mx={"24px"}
                      cursor={"pointer"}
                      onClick={(e) => setIsEditing(true)}
                    >
                      <Text
                        fontSize={"25px"}
                        fontWeight={"600"}
                        fontFamily={"Beatrice-Regular"}
                        color={"#2a2f56"}
                        pr={"12px"}
                        style={{
                          maxWidth: '800px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {input}
                      </Text>
                      <Image
                        src="/ic_edit.png"
                        width={"16px"}
                        height={"16px"}
                        mt={"4px"}
                      />
                    </Flex>
                  </Flex>
                )}
                <Flex 
                  width={"50%"} 
                  justifyContent={"end"}
                  px={"24px"}
                >
                  <Button
                    fontSize={"14px"}
                    variant={"offset-export"}
                    _focus={{ boxShadow: "none" }}
                    onClick={handleNLQuery}
                    leftIcon={<FaRobot/>}
                  >
                    Natural Language Query
                  </Button>
                </Flex>
              </Flex>
              {nlWords && (
                <Flex
                  borderRadius={"4px"}
                  backgroundColor={errorWords ? "#FFB9AC" : "#FDF2EF"}
                  direction={"column"}
                  mt={"10px"}
                  mb={"20px"}
                  mx={"24px"}
                  px={"16px"}
                  py={"12px"}
                  alignItems={"start"}
                >
                  <Flex width={"100%"}>
                    <FaRobot/>
                    <Text
                      textAlign={"start"}
                      width={"100%"}
                      fontSize={"15px"}
                      color={"#2A2F56"}
                      ml={"12px"}
                    >
                      {nlWords}
                    </Text>
                    <Box
                      px={"5px"}
                      py={"2px"}
                      cursor={"pointer"}
                      onClick={handleNLEvaluateUp}
                    >
                      <FaRegThumbsUp/>
                    </Box>
                    <Box
                      px={"5px"}
                      py={"2px"}
                      ml={"10px"}
                      cursor={"pointer"}
                      onClick={handleNLEvaluateDown}
                    >
                      <FaRegThumbsDown/>
                    </Box>
                  </Flex>
                  {errorWords && (
                    <Text
                      fontSize={"15px"}
                      color={"#2A2F56"}
                      mt={"12px"}
                      textAlign={"left"}
                    >
                      {errorWords}
                    </Text>
                  )}
                </Flex>
              )}
              <QueryBuilder
                entitiesToQueryBy={[
                  EntitiesEnum.GROUPS, 
                  EntitiesEnum.CUSTOMERS,
                  EntitiesEnum.ORDERS,
                  EntitiesEnum.PRODUCTS,
                ]}
                segment={segment}
                onQueryUpdate={handleQueryUpdate}
                onUpdateResults={handleExecuteQuery}
              />
              <Flex mx={"24px"}>
                <Button
                  fontSize={"14px"}
                  disabled={input === ""}
                  _focus={{ boxShadow: "none" }}
                  onClick={handleExecuteQuery}
                >
                  Save and preview
                </Button>
                {nlEvaluate == 0 && (
                  <Button
                    ml={2}
                    fontSize={"14px"}
                    disabled={input === ""}
                    _focus={{ boxShadow: "none" }}
                    onClick={handleQueryFeedback}
                  >
                    This looks correct
                  </Button>
                )}
              </Flex>
              {segment && segment.id != "" && (
                <Flex 
                  mt={"32px"}
                  px={"24px"}
                  py={"20px"}
                  alignItems={"center"} 
                  gridGap={4} 
                  backgroundColor={"#F3F4F5"}
                  borderColor={"#DEE2E6"}
                  borderWidth={"1px"}
                >
                  <Flex alignItems={"end"} mr={"10px"}>
                    <Text
                      fontSize={"25px"}
                      color={"#2A2F56"}
                    >
                      {page.total.toLocaleString()}
                    </Text>
                    <Text
                      fontSize={"13px"}
                      color={"#4F5268"}
                      ml={"10px"}
                      mb={"4px"}
                    >
                      Customers in this segment
                    </Text>
                  </Flex>
                  {customers.length > 0 && (
                    <Flex ml="auto" alignItems={"center"}>
                      <Button
                        fontSize={"14px"}
                        variant={"offset-export"}
                        height={"38px"}
                        _focus={{ boxShadow: "none" }}
                        leftIcon={<Image width={"20px"} height={"20px"} src="/ic_export.png"/>}
                        onClick={handleCsvDownload}
                      >
                        Export segment
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </Box>
          )}
          <Box w={"100%"} backgroundColor={"white"} borderBottomRadius={"4px"} pb={checkedCustomers.length > 0 ? "80px" : "0px"}>
            {isTableLoading ? (
              <>
                {isLoading ? (
                  <></>
                ) : (
                  <Spinner color="orange.100" mt={"48px"}/>
                )}
              </>
            ) : (
              <>
                {segment && segment.id != "" && (
                  <>
                    <TableContainer>
                      <Table colorScheme={"blackAlpha"} size="sm">
                        <Thead backgroundColor={"#F3F4F5"}>
                          <Tr>
                            <Th py={"12px"} pl={"24px"}>
                              <CheckboxStyled
                                isChecked={allChecked}
                                isIndeterminate={isIndeterminate}
                                onChange={toggleAllCheckbox}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(0)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>ID</Text>
                                {
                                  selTableIndex == 0 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 0 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(1)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Status</Text>
                                {
                                  selTableIndex == 1 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 1 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(2)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Joined</Text>
                                {
                                  selTableIndex == 2 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 2 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(3)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Customer</Text>
                                {
                                  selTableIndex == 3 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 3 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(4)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Contact</Text>
                                {
                                  selTableIndex == 4 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 4 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(5)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Source</Text>
                                {
                                  selTableIndex == 5 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 5 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(6)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Referral</Text>
                                {
                                  selTableIndex == 6 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 6 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(7)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Lifetime</Text>
                                {
                                  selTableIndex == 7 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 7 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              pr={"24px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(8)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Credits</Text>
                                {
                                  selTableIndex == 8 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 8 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customers.map((customer: Customer, i) => (
                            <Tr key={i}>
                              <Td pl={"24px"}>
                                <CheckboxStyled
                                  isChecked={checkedCustomers.includes(customer.remote_id)}
                                  onChange={() => toggleCheckbox(customer.remote_id)}
                                />
                              </Td>
                              <Td>{customer.remote_id}</Td>
                              <Td>
                                <Flex>
                                {customer.status=="Active" ? (
                                  <Text 
                                    backgroundColor={"#A7EAD5"}
                                    color={"#174F3E"}
                                    px={"6px"}
                                    py={"4px"}
                                    fontSize={"11px"}
                                    borderRadius={"2px"}
                                  >
                                    {customer.status}
                                  </Text>
                                ) : (
                                  <Text 
                                    backgroundColor={"#FECDCD"}
                                    color={"#A32626"}
                                    px={"6px"}
                                    py={"4px"}
                                    fontSize={"11px"}
                                    borderRadius={"2px"}
                                  >
                                    {customer.status}
                                  </Text>
                                )}
                                </Flex>
                              </Td>
                              <Td>
                                <div>{`${new Date(
                                  customer.created_date ? customer.created_date : customer.created
                                ).toLocaleDateString()}`}</div>
                                <div>{customer.created_time}</div>
                              </Td>
                              <Td
                                maxWidth={"200px"}
                                whiteSpace={"pre-wrap"}
                                overflowWrap={"break-word"}
                                cursor={"pointer"}
                                onClick={() => handleViewCustomer(customer)}
                              >
                                {customer.first_name + " " + customer.last_name}
                              </Td>
                              <Td
                                maxWidth={"250px"}
                                whiteSpace={"pre-wrap"}
                                overflowWrap={"break-word"}
                              >
                                <div>{customer.email}</div>
                                <div>{customer.phone}</div>
                              </Td>
                              <Td>{customer.customer_source}</Td>
                              <Td>{customer.referral_source}</Td>
                              <Td>{formatCurrency(customer.order_total)}</Td>
                              <Td pr={"24px"}>{formatCurrency(customer.credits)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Box mt={3} mb={5}>
                      <Pagination
                        current={page.pageIndex}
                        total={page.total}
                        pageSize={page.pageSize}
                        onShowSizeChange={onShowSizeChange}
                        onChange={onPageChange}
                        showTotal={(total, range) =>
                          `${range[0]} - ${range[1]} of ${total.toLocaleString()} items`
                        }
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
          {isAddToGroupOpen && (
            <AddToGroupModal
              isOpen={isAddToGroupOpen}
              onClose={onAddToGroupClose}
              customerIds={checkedCustomers}
              totalChecked={totalChecked}
              total={page.total}
              accountId={userInfo?.currentAccount?.id}
              ruleGroups={segment?.ruleGroups}

            />
          )}
          {isAddNotesOpen && (
            <AddNotesModal
              isOpen={isAddNotesOpen}
              onClose={onAddNotesClose}
              customerIds={checkedCustomers}
              totalChecked={totalChecked}
              total={page.total}
              accountId={userInfo?.currentAccount?.id}
              ruleGroups={segment?.ruleGroups}
            />
          )}
          {isAddTagsOpen && (
            <AddTagsModal
              isOpen={isAddTagsOpen}
              onClose={onAddTagsClose}
              customerIds={checkedCustomers}
              totalChecked={totalChecked}
              total={page.total}
              accountId={userInfo?.currentAccount?.id}
              ruleGroups={segment?.ruleGroups}
            />
          )}
          {isEditCustomerOpen && (
            <EditCustomerModal
              isOpen={isEditCustomerOpen}
              onClose={handleEditCustomerClose}
              customer={customers.find((c) => c.remote_id === checkedCustomers[0])}
            />
          )}
          {isMoveToGroupOpen && (
            <AddToGroupModal
              isOpen={isMoveToGroupOpen}
              onClose={onMoveToGroupClose}
              customerIds={checkedCustomers}
              clearAllBefore={true}
              totalChecked={totalChecked}
              total={page.total}
              accountId={userInfo?.currentAccount?.id}
              ruleGroups={segment?.ruleGroups}
            />
          )}
          {isCustomerInfoOpen && (
            <CustomerInfoModal
              isOpen={isCustomerInfoOpen}
              onClose={onCustomerInfoClose}
              customer={selecetedCustomer}
            />
          )}
          {isNLQueryOpen && (
            <NLQueryModal
              isOpen={isNLQueryOpen}
              onClose={onNLQueryClose}
              onNLQueryCommit={onNLQueryCommit}
              segment={segment}
              defaultModel={defaultModel}
              defaultShow={defaultShow}
              nlWords={nlWords}
            />
          )}
        </Flex>
      </Flex>
      <animated.div
        style={{
          ...springs
        }}
      >
        {showBottomBar && (
          <Flex
            width={width - 260}
            height={"80px"}
            position={"absolute"}
            backgroundColor={"white"}
            borderBottomRadius={"4px"}
            direction={"column"}
          >
            <Image
              width={"100%"}
              height={"3px"}
              backgroundColor={"black"}
            />
            <Flex
              ml={"24px"}
              mt={"20px"}
              alignItems={"center"}
            >
              <Select
                  menuPlacement="top"
                  value={actions?.find(
                    (c) => c.value === selectedAction
                  )}
                  options={actions?.map(
                    (action) => ({
                      value: action.value,
                      label: action.label,
                      isDisabled: action.isDisabled
                    })
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: '14px',
                      minWidth: '300px',
                      minHeight: '40px',
                      textAlign: 'left'
                    }),
                    option: (styles, {isSelected, isFocused, isDisabled}) => ({
                      ...styles,
                      backgroundColor: isSelected ? '#FFB9AC' : isFocused ? '#E7EAEC' : 'white',
                      color: isDisabled ? '#cccccc' : '#2A2F56',
                      fontSize: '14px',
                      paddingLeft: '24px',
                      textAlign: 'left',
                    }),
                  }}
                  onChange={(e) => {
                    if(e?.value){
                      setSelectedAction(e?.value);

                      switch(e?.value){
                        case "1":
                          handleAddToGroup();
                          break;
                        case "2":
                          handleMoveToGroup();
                          break;
                        case "3":
                          handleAddNotes();
                          break;
                        case "4":
                          handleAddTags();
                          break;
                        case "5":
                          handleOnEdit();
                          break;
                      }
                    }
                  }}
                />
              <Text
                fontSize={"16px"}
                fontWeight={"bold"}
                color={"#2A2F56"}
                alignItems={"center"}
                ml={"24px"}
              >
                {totalChecked ? page.total.toLocaleString() : checkedCustomers.length}
              </Text>
              <Text
                fontSize={"16px"}
                color={"#4F5268"}
                ml={"4px"}
                mr={"24px"}
                alignItems={"center"}
              >
                selected
              </Text>
              {allChecked && (
                <Flex direction={"row"}>
                  <CheckboxStyled
                    isChecked={totalChecked}
                    onChange={() => toggleTotalCheckbox()}
                  />
                  <Text
                    fontSize={"16px"}
                    color={"#4F5268"}
                    ml={"5px"}
                    alignItems={"center"}
                  >
                    Select All
                  </Text>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    color={"#2A2F56"}
                    alignItems={"center"}
                    ml={"5px"}
                  >
                    {page.total.toLocaleString()}
                  </Text>
                  <Text
                    fontSize={"16px"}
                    color={"#4F5268"}
                    ml={"6px"}
                    alignItems={"center"}
                  >
                    Customers
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </animated.div>
    </Flex>
  );
};

export default Segment;
