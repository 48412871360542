import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import QueryBuilder from "../../components/QueryBuilder/QueryBuilder";
import {
  createSegment,
  getSegmentById,
  SegmentType,
  updateSegment,
} from "../../services/segments";
import {
  Button,
  Text,
  Image,
  Input,
  Textarea,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { FaRobot, FaRegThumbsUp, FaRegThumbsDown, FaSort, FaSortUp, FaSortDown, FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";
import { RuleRowType } from "../../components/QueryBuilder/RuleRow";
import { CheckboxStyled, CheckboxNormal } from "../../components/Checkbox/Checkbox";
import csvDownload from "json-to-csv-export";
import { useToken } from "../../services/auth";
import { EntitiesEnum } from "../../services/queryBuilder";
import { getOrdersByRuleGroups, Order } from "../../services/orders";
import { formatCurrency, getUploadRuleGroups } from "../../utils/formatters";
import { query, getRuleGroupValueFromNL, QueryResult, Expression, doNLEvaluate, doNLFeedback, getQueryLogicCode } from "../../services/nlQuery";
import NavBar from "../../components/NavBar/NavBar";
import {
  PAGE_INDEX_SEGMENTS, 
} from "../../utils/constants";

type NLModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onNLQueryCommit: (queryId: string, words: string, querySegment: SegmentType | null, errorMessage: string | null) => void;
  segment: SegmentType | undefined;
  defaultModel: string;
  defaultShow: boolean;
  nlWords: string;
};

type OrderSegmentProps = {
  signOut: () => void;
  setPageIndex: (pageIndex: number) => void;
};

const NLQueryModal = ({ isOpen, onClose, onNLQueryCommit, segment, defaultModel, defaultShow, nlWords }: NLModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputWords, setInputWords] = useState<string>(nlWords);
  const models = [{label: "GPT-3.5 Fastest model. Great for everyday queries.", value: "3"}, {label: "GPT-4.5 Most capable. Good for advanced queries.", value: "4"}]
  const [selectedModel, setSelectedModel] = useState<string>(defaultModel);
  const [needShowQuestion, setNeedShowQuestion] = useState<boolean>(defaultShow);
  const { userInfo } = useToken();
  const toast = useToast();

  const onCommitNLQuery = async () => {
    if(!selectedModel){
      toast({
        title: "Please select GPT Model.",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
      return;
    }
    if(!inputWords){
      toast({
        title: "Please input natural language.",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
      return;
    }
    try {
      setIsLoading(true);
      localStorage.setItem("model", selectedModel)
      localStorage.setItem("need_show_question", needShowQuestion ? "true" : "false")

      const queryData = await query(EntitiesEnum.ORDERS, inputWords, selectedModel, needShowQuestion, "", userInfo?.currentAccount?.id);
      if(queryData.result_text){
        var queryResult: QueryResult
        if(queryData.result_text.includes("\"expression\"") || queryData.result_text.includes("\"error_message\"")){
          queryResult = JSON.parse(queryData.result_text) as QueryResult;
        }else{
          queryResult = {} as QueryResult
          queryResult.expression = JSON.parse(queryData.result_text) as Expression;
        }
        if(queryResult.expression){
          var querySegment: SegmentType
          if(segment){
            querySegment = segment
          }else{
            querySegment = {
              id: "",
              name: "",
              ruleGroups: [],
              entity_type: EntitiesEnum.ORDERS,
              not_purchased: 0
            }
          }
  
          querySegment.ruleGroups.splice(0, segment?.ruleGroups.length)
          getRuleGroupValueFromNL(
            queryResult.expression, 
            [
              EntitiesEnum.ORDERS,
              EntitiesEnum.PRODUCTS,
              EntitiesEnum.CUSTOMERS,
            ]).forEach((e) =>{
            querySegment.ruleGroups.push(e)
          })
          onNLQueryCommit(queryData.id.toString(), inputWords, querySegment, "");
        }else if(queryResult.error_message){
          onNLQueryCommit(queryData.id.toString(), inputWords, null, queryResult.error_message)
        }else{
          toast({
            title: "Natural Language Query failed.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: false,
            });
        }
      }else{
        toast({
          title: "Natural Language Query failed.",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: false,
          });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Natural Language Query failed.",
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Natural Language Query</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            placeholder="Select GPT Model..."
            value={models?.find(
              (c) => c.value === selectedModel
            )}
            options={models?.map(
              (model) => ({
                value: model.value,
                label: model.label,
              })
            )}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px',
                minHeight: '40px',
              }),
              option: (styles, {isSelected, isFocused}) => ({
                ...styles,
                backgroundColor: isSelected ? '#FFB9AC' : isFocused ? '#E7EAEC' : 'white',
                color: '#2A2F56',
                fontSize: '14px',
                paddingLeft: '24px',
              }),
            }}
            onChange={(e) => {
              if(e?.value){
                setSelectedModel(e?.value);
              }
            }}
          />
          <Textarea
            multiple
            mt={"20px"}
            height={"100px"}
            fontSize={"14px"}
            borderColor={"#cccccc"}
            onChange={(e) => setInputWords(e.target.value)}
            placeholder="Use natural language to search orders"
            type={"text"}
            value={inputWords}
          />
          {/* <CheckboxNormal
            isChecked={needShowQuestion}
            onChange={() => setNeedShowQuestion(!needShowQuestion)}
            mt={"20px"}
          >
            Show question to clarify
          </CheckboxNormal> */}
        </ModalBody>
        <ModalFooter>
          <Button variant="offset-light" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button onClick={onCommitNLQuery} isLoading={isLoading} variant="offset">
            Commit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const OrderSegment = ({signOut, setPageIndex}: OrderSegmentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFirstQuery, setIsFirstQuery] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [segment, setSegment] = useState<SegmentType | undefined>(undefined);
  const [input, setInput] = useState<string>("");
  const [defaultModel, setDefaultModel] = useState<string>("");
  const [defaultShow, setDefaultShow] = useState<boolean>(false)
  const [nlWords, setNLWords] = useState<string>("");
  const [nlQueryId, setNLQueryId] = useState<string>("");
  const [nlEvaluate, setNLEvaluate] = useState<number>(-1);
  const [errorWords, setErrorWords] = useState<string>("")
  const [checkedOrders, setCheckedOrders] = useState<string[]>([]);
  const [selTableIndex, setSelTableIndex] = useState<number>(0);
  const [sortType, setSortType] = useState<number>(0);
  const [pageSizeChanges, setPageSizeChanges] = useState<boolean>(false);

  const [page, setPage] = useState({
    total: 0,
    pageSize: 50,
    pageIndex: 1,
  });

  const { userInfo, setToken } = useToken();

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();

  const allChecked = useMemo(
    () =>
      checkedOrders.length >= orders.length &&
      orders.every((item) => checkedOrders.includes(item.remote_id)),
    [checkedOrders, orders]
  );
  const isIndeterminate = useMemo(
    () =>
      checkedOrders.length > 0 &&
      !allChecked &&
      orders.some((item) => checkedOrders.includes(item.remote_id)),
    [checkedOrders, allChecked, orders]
  );

  const judgeExpired = () => {
    var isExpired = true;
    var loginTimeStr = localStorage.getItem("login_time");
    if(loginTimeStr && loginTimeStr.length > 0){
      var loginTime = Number.parseInt(loginTimeStr);
      var currentTime = new Date().getTime();
      isExpired = currentTime - loginTime > 3 * 24 * 3600 * 1000;
    }
    if(isExpired){
      signOut();
    }

    return isExpired;
  }

  // Natural Language Query modal
  const {
    isOpen: isNLQueryOpen,
    onOpen: onNLQueryOpen,
    onClose: onNLQueryClose,
  } = useDisclosure();

  const getFloatValue = (valueStr: string) => {
    if(valueStr && valueStr.length > 0){
      return Number.parseFloat(valueStr);
    }

    return 0;
  }

  const getStringLowerValue = (valueStr: string) => {
    if(valueStr && valueStr.length > 0){
      return valueStr.toLowerCase();
    }

    return "";
  }

  const handleTableSort = (columnIndex: number) => {
    var currentSortType = sortType;
    if(columnIndex == selTableIndex){
      currentSortType++;
      if(currentSortType > 3){
        currentSortType = 0;
      }
    }else{
      setSelTableIndex(columnIndex);
      currentSortType = 0;
    }
    setSortType(currentSortType);
    if(currentSortType <= 1){
      doSort(orders, columnIndex, currentSortType);
    }else if(segment){
      setCheckedOrders([]);
      if(page.pageIndex == 1){
        loadOrders(segment.ruleGroups, columnIndex, currentSortType)
      }else{
        setPage({
          ...page,
          pageIndex: 1,
        });
      }
    }

  };

  const doSort = (orders: Order[], columnIndex: number, currentSortType: number) =>{
    switch(columnIndex){
      case 0:
        if(currentSortType == 0){
          orders.sort((a, b) => Number.parseInt(a.remote_id) - Number.parseInt(b.remote_id));
        }else{
          orders.sort((a, b) => Number.parseInt(b.remote_id) - Number.parseInt(a.remote_id));
        }
        break;
      case 1:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.first_name) > getStringLowerValue(b.first_name) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.first_name) < getStringLowerValue(b.first_name) ? 1 : -1);
        }
        break;
      case 2:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.last_name) > getStringLowerValue(b.last_name) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.last_name) < getStringLowerValue(b.last_name) ? 1 : -1);
        }
        break;
      case 3:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.ship_status) > getStringLowerValue(b.ship_status) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.ship_status) < getStringLowerValue(b.ship_status) ? 1 : -1);
        }
        break;
      case 4:
        if(currentSortType == 0){
          orders.sort((a, b) => a.total - b.total);
        }else{
          orders.sort((a, b) => b.total - a.total);
        }
        break;
      case 5:
        if(currentSortType == 0){
          orders.sort((a, b) => a.item_count - b.item_count);
        }else{
          orders.sort((a, b) => b.item_count - a.item_count);
        }
        break;
      case 6:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.payment_status) > getStringLowerValue(b.payment_status) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.payment_status) < getStringLowerValue(b.payment_status) ? 1 : -1);
        }
        break;
      case 7:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.state) > getStringLowerValue(b.state) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.state) < getStringLowerValue(b.state) ? 1 : -1);
        }
        break;
      case 8:
        if(currentSortType == 0){
          orders.sort((a, b) => getStringLowerValue(a.city) > getStringLowerValue(b.city) ? 1 : -1);
        }else{
          orders.sort((a, b) => getStringLowerValue(a.city) < getStringLowerValue(b.city) ? 1 : -1);
        }
        break;
    }
  }

  const onNLQueryCommit = (queryId: string, words: string, querySegment: SegmentType | null, errorMessage: string | null) => {
    setNLEvaluate(-1);
    setNLQueryId(queryId);
    if(querySegment){
      handleQueryUpdate(querySegment);
      setNLWords(words);
      setErrorWords("");
      if(isFirstQuery){
        setIsFirstQuery(false);
        if(segment){
          handleExecuteQuery();
        }
      }
    }else if(errorMessage){
      setNLWords(words);
      setErrorWords(errorMessage);
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditing(true);
    setInput(e.target.value);
  };

  const handleQueryUpdate = async (newSegment: SegmentType) => {
    if (segment) {
      setSegment({ ...segment, ruleGroups: newSegment.ruleGroups });
    } else {
      setSegment(newSegment);
    }
  };

  const handleNLQuery = () => {
    var localModel = localStorage.getItem("model")
    var localShow = localStorage.getItem("need_show_question") == "true"
    setDefaultModel(localModel ? localModel : "")
    setDefaultShow(localShow ? localShow : false)

    onNLQueryOpen();
  };

  const handleNLEvaluateUp = async () => {
    await doNLEvaluate(nlQueryId, 1);
    setNLEvaluate(1);
    toast({
      title: "Send evaluate success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleNLEvaluateDown = async () => {
    await doNLEvaluate(nlQueryId, 0);
    setNLEvaluate(0);
    toast({
      title: "Send evaluate success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleQueryFeedback = async () => {
    var logicCode = "";
    if(segment?.ruleGroups){
      logicCode = getQueryLogicCode(segment?.ruleGroups);
    }
    await doNLFeedback(nlQueryId, logicCode);
    setNLEvaluate(-1);
    toast({
      title: "Send feedback success!",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: false,
      });
  }

  const handleExecuteQuery = async () => {
    if (!userInfo?.currentAccount) {
      return;
    }

    try {
      setIsTableLoading(true);
      if (!segment || segment.id === "") {
        const newSegment = await createSegment(
          {
            name: input,
            id: "",
            ruleGroups: segment?.ruleGroups || [],
            entity_type: EntitiesEnum.ORDERS,
            not_purchased: 0,
            account_id: userInfo.currentAccount.id,
          },
          userInfo.currentAccount.id
        );
        setSegment(newSegment);
        toast({
          title: "Segment created!",
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: false,
          });
        history.push(`/segs/${EntitiesEnum.ORDERS}/${newSegment.id}`);
      } else {
        const updatedSegment = {
          ...segment,
          name: input,
          account_id: userInfo.currentAccount.id,
        };
        await updateSegment(updatedSegment);
        setSelTableIndex(0);
        setSortType(0);
        if(page.pageIndex == 1){
          await loadOrders(updatedSegment.ruleGroups, 0, 0);
        }else{
          setPage({
            ...page,
            pageIndex: 1,
          });
        }
        setSegment(updatedSegment);
        toast({
          title: "Segment updated!",
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: false,
          });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadOrders = useCallback(
    async (ruleGroups: RuleRowType[][], sortIndex: number, sortType: number) => {
      try {
        if (!userInfo?.currentAccount) {
          throw new Error("No account id");
        }
        if (judgeExpired()) {
          return;
        }

        setIsTableLoading(true);
        const { pageIndex, pageSize } = page;
        const pageObject = {
          pageIndex,
          pageSize,
        };
        const { data, total } = await getOrdersByRuleGroups(
          getUploadRuleGroups(ruleGroups),
          userInfo?.currentAccount?.id,
          pageObject,
          sortType > 1 ? sortIndex : 0,
          sortType > 1 ? sortType - 2 : 0,
        );
        if(sortType <= 1){
          doSort(data, sortIndex, sortType);
        }
        setOrders(data);
        setPage({
          ...page,
          total,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsTableLoading(false);
      }
    },
    [userInfo?.currentAccount?.id, page.pageIndex, page.pageSize]
  );

  const loadSegment = useCallback(async () => {
    if (id === "new") {
      setIsTableLoading(false);
      return;
    }
    if (judgeExpired()) {
      return;
    }

    try {
      if(!segment){
        setIsLoading(true);
        const onlineSegment = await getSegmentById(id);
        setSegment(onlineSegment);
        setInput(onlineSegment.name);
        setIsLoading(false);
        await loadOrders(onlineSegment.ruleGroups, 0, 0);
      }else if(pageSizeChanges){
        setPageSizeChanges(false);
        setPage({
          ...page,
          pageIndex: 1,
        });
      }else{
        await loadOrders(segment.ruleGroups, selTableIndex, sortType);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [id, loadOrders]);

  const remoteIds: string[] = useMemo(() => {
    return orders.map((order) => order.remote_id);
  }, [orders]);

  const toggleAllCheckbox = () => {
    if (allChecked) {
      const temp = new Set(checkedOrders);
      remoteIds.map((item) => temp.delete(item));
      setCheckedOrders(Array.from(temp));
    } else {
      setCheckedOrders(checkedOrders.concat(remoteIds));
    }
  };

  const toggleCheckbox = (orderId: string) => {
    const isPreviouslyChecked = checkedOrders.includes(orderId);
    if (isPreviouslyChecked) {
      setCheckedOrders((prev) => prev.filter((id) => id !== orderId));
    } else {
      setCheckedOrders((prev) => [...prev, orderId]);
    }
  };

  const onShowSizeChange = (current: number, pageSize: number): void => {
    if(current > 1){
      setPageSizeChanges(true);
    }
    setPage({
      ...page,
      pageIndex: current,
      pageSize,
    });
  };

  const onChange = (current: number, pageSize: number): void => {
    setPage({
      ...page,
      pageIndex: current,
      pageSize,
    });
  };

  const handleCsvDownload = useCallback(async () => {
    // const { currentAccountId } = userInfo;
    // const { ruleGroups } = segment;
    if (!(segment || userInfo)) return;
    const { data } = await getOrdersByRuleGroups(
      segment!.ruleGroups,
      userInfo?.currentAccount?.id,
      undefined,
      0,
      0,
      true
    );
    csvDownload({
      data: data,
      filename: `orders_${new Date().toISOString()}`,
    });
  }, [segment, userInfo]);

  useEffect(() => {
    // setPageIndex(PAGE_INDEX_SEGMENTS);
    // loadSegment();
    history.push(`/segs/customer`);
  }, [loadSegment]);

  return (
    <Flex direction={"column"} height={"100%"}>
      <NavBar removeToken={() => signOut()} entityType={EntitiesEnum.ORDERS} paramName={"segs"}/>
      <Flex direction={"column"} alignItems={"start"} backgroundColor={"white"} height={"100%"} borderRadius={"4px"}>
        {isLoading ? (
          <Flex
            h={"300px"}
            w={"100%"}
            mx={"24px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner color="orange.100" />
          </Flex>
        ) : (
          <>
            <Box w={"100%"} mt={4}>
              <Flex>
                {(isEditing || !input) ? (
                  <Flex 
                    alignItems={"center"} 
                    width={"50%"} 
                    height={"44px"}
                    justifyContent={"end"}
                    borderColor={"DFE2E6"}
                    borderWidth={"1px"}
                    borderRadius={"4px"}
                    mx={"24px"}>
                    <Input
                      autoFocus
                      onChange={handleNameChange}
                      borderColor={"white"}
                      placeholder="Name..."
                      fontSize={"14px"}
                      color={"#2a2f56"}
                      value={input}
                      ml={"2px"}
                      mt={"8px"}
                      pr={"12px"}
                      height={"40px"}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsEditing(false);
                          handleExecuteQuery();
                        }
                      }}
                    />
                    <Image
                      src="/ic_enter.png"
                      width={"12px"}
                      mr={"15px"}
                    />
                    <Image
                      src="/ic_corner.png"
                      width={"8px"}
                      mr={"3px"}
                      mt={"18px"}/>
                  </Flex>
                ):(
                  <Flex width={"70%"}>
                    <Flex 
                      alignItems={"center"}
                      height={"48px"}
                      mx={"24px"}
                      cursor={"pointer"}
                      onClick={(e) => setIsEditing(true)}
                    >
                      <Text
                        fontSize={"25px"}
                        fontWeight={"600"}
                        fontFamily={"Beatrice-Regular"}
                        color={"#2a2f56"}
                        pr={"12px"}
                        style={{
                          maxWidth: '800px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {input}
                      </Text>
                      <Image
                        src="/ic_edit.png"
                        width={"16px"}
                        height={"16px"}
                        mt={"4px"}
                      />
                    </Flex>
                  </Flex>
                )}
                <Flex 
                  width={"50%"} 
                  justifyContent={"end"}
                  px={"24px"}
                >
                  <Button
                    fontSize={"14px"}
                    variant={"offset-export"}
                    _focus={{ boxShadow: "none" }}
                    onClick={handleNLQuery}
                    leftIcon={<FaRobot/>}
                  >
                    Natural Language Query
                  </Button>
                </Flex>
              </Flex>
              {nlWords && (
                <Flex
                  borderRadius={"4px"}
                  backgroundColor={errorWords ? "#FFB9AC" : "#FDF2EF"}
                  direction={"column"}
                  mt={"10px"}
                  mb={"20px"}
                  mx={"24px"}
                  px={"16px"}
                  py={"12px"}
                  alignItems={"start"}
                >
                  <Flex width={"100%"}>
                    <FaRobot/>
                    <Text
                      textAlign={"start"}
                      width={"100%"}
                      fontSize={"15px"}
                      color={"#2A2F56"}
                      ml={"12px"}
                    >
                      {nlWords}
                    </Text>
                    <Box
                      px={"5px"}
                      py={"2px"}
                      cursor={"pointer"}
                      onClick={handleNLEvaluateUp}
                    >
                      <FaRegThumbsUp/>
                    </Box>
                    <Box
                      px={"5px"}
                      py={"2px"}
                      ml={"10px"}
                      cursor={"pointer"}
                      onClick={handleNLEvaluateDown}
                    >
                      <FaRegThumbsDown/>
                    </Box>
                  </Flex>
                  {errorWords && (
                    <Text
                      fontSize={"15px"}
                      color={"#2A2F56"}
                      mt={"12px"}
                      textAlign={"left"}
                    >
                      {errorWords}
                    </Text>
                  )}
                </Flex>
              )}
              <QueryBuilder
                onUpdateResults={handleExecuteQuery}
                entitiesToQueryBy={[
                  EntitiesEnum.ORDERS,
                  EntitiesEnum.PRODUCTS,
                  EntitiesEnum.CUSTOMERS,
                ]}
                segment={segment}
                onQueryUpdate={handleQueryUpdate}
              />
              <Flex mx={"24px"}>
                <Button
                  fontSize={"14px"}
                  disabled={input === ""}
                  _focus={{ boxShadow: "none" }}
                  onClick={handleExecuteQuery}
                >
                  Save and preview
                </Button>
                {nlEvaluate == 0 && (
                  <Button
                    ml={2}
                    fontSize={"14px"}
                    disabled={input === ""}
                    _focus={{ boxShadow: "none" }}
                    onClick={handleQueryFeedback}
                  >
                    This looks correct
                  </Button>
                )}
              </Flex>
              {segment && segment.id != "" && (
                <Flex 
                  mt={"32px"}
                  px={"24px"}
                  py={"20px"}
                  alignItems={"center"} 
                  gridGap={4} 
                  backgroundColor={"#F3F4F5"}
                  borderColor={"#DEE2E6"}
                  borderWidth={"1px"}
                >
                  <Flex alignItems={"end"}>
                    <Text
                      fontSize={"25px"}
                      color={"#2A2F56"}
                    >
                      {page.total.toLocaleString()}
                    </Text>
                    <Text
                      fontSize={"13px"}
                      color={"#4F5268"}
                      ml={"10px"}
                      mb={"4px"}
                    >
                      Orders in this segment
                    </Text>
                  </Flex>
                  {orders.length > 0 && (
                    <Flex ml="auto" alignItems={"center"}>
                      <Button
                        fontSize={"14px"}
                        variant={"offset-export"}
                        height={"38px"}
                        _focus={{ boxShadow: "none" }}
                        leftIcon={<Image width={"20px"} height={"20px"} src="/ic_export.png"/>}
                        onClick={handleCsvDownload}
                        >
                        Export segment
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </Box>
          </>
        )}
        <Box w={"100%"} overflowX="scroll">
          {isTableLoading ? (
            <>
              {isLoading ? (
                <></>
              ) : (
                <Spinner color="orange.100" mt={"48px"}/>
              )}
            </>
          ) : (
            <>
              {segment && segment.id != "" && (
                <>
                  <TableContainer>
                    <Table colorScheme={"blackAlpha"} size="sm">
                      <Thead backgroundColor={"#F3F4F5"}>
                        <Tr>
                          <Th py={"12px"} pl={"24px"}>
                            <CheckboxStyled
                              isChecked={allChecked}
                              isIndeterminate={isIndeterminate}
                              onChange={toggleAllCheckbox}
                            />
                          </Th>
                          <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(0)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>ID</Text>
                                {
                                  selTableIndex == 0 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 0 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(1)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>First Name</Text>
                                {
                                  selTableIndex == 1 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 1 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(2)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Last Name</Text>
                                {
                                  selTableIndex == 2 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 2 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(3)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Ship Status</Text>
                                {
                                  selTableIndex == 3 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 3 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(4)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Total</Text>
                                {
                                  selTableIndex == 4 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 4 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(5)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Item Count</Text>
                                {
                                  selTableIndex == 5 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 5 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(6)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>Payment Status</Text>
                                {
                                  selTableIndex == 6 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 6 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(7)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>State</Text>
                                {
                                  selTableIndex == 7 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 7 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                            <Th
                              fontSize={"13px"}
                              pr={"24px"}
                              cursor={"pointer"}
                              onClick={(e) => handleTableSort(8)}>
                              <Flex
                                direction={"row"}
                                alignItems={"center"}
                                height={"40px"}>
                                <Text mr={"4px"}>City</Text>
                                {
                                  selTableIndex == 8 ? 
                                  (sortType == 0 ? 
                                  <FaSortUp/> 
                                  : (sortType == 1 ? 
                                  <FaSortDown/> 
                                  : (sortType == 2 ? 
                                  <FaAngleDoubleUp/> 
                                  : <FaAngleDoubleDown/>))) 
                                  : <FaSort/>
                                }
                              </Flex>
                              <Image
                                width={"100%"}
                                height={"3px"}
                                opacity={selTableIndex == 8 ? "1" : "0"}
                                backgroundColor={"black"}
                                alignSelf={"flex-end"}
                              />
                            </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {orders.map((order: Order, i) => (
                          <Tr key={i}>
                            <Td pl={"24px"}>
                              <CheckboxStyled
                                isChecked={checkedOrders.includes(order.remote_id)}
                                onChange={() => toggleCheckbox(order.remote_id)}
                              />
                            </Td>
                            <Td>{order.remote_id}</Td>
                            <Td>{order.first_name}</Td>
                            <Td>{order.last_name}</Td>
                            <Td>{order.ship_status}</Td>
                            <Td>{formatCurrency(order.total)}</Td>
                            <Td>{order.item_count}</Td>
                            <Td>{order.payment_status}</Td>
                            <Td>{order.state}</Td>
                            <Td pr={"24px"}>{order.city}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Box mt={3} mb={5}>
                    <Pagination
                      current={page.pageIndex}
                      total={page.total}
                      pageSize={page.pageSize}
                      onShowSizeChange={onShowSizeChange}
                      onChange={onChange}
                      showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} of ${total.toLocaleString()} items`
                      }
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
        {isNLQueryOpen && (
          <NLQueryModal
            isOpen={isNLQueryOpen}
            onClose={onNLQueryClose}
            onNLQueryCommit={onNLQueryCommit}
            segment={segment}
            defaultModel={defaultModel}
            defaultShow={defaultShow}
            nlWords={nlWords}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default OrderSegment;
