import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Heading,
  Button,
  useToast,
  Select,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  Account,
  authenticateUserWith2FA,
  authenticateUserWithCreds,
  getUserTokenByAccount,
  UserInfo,
} from "../../services/auth";
import { Link as RouterDomLink } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import CustomInput from "../../components/CustomInput/CustomInput";
import { useHistory } from "react-router-dom";

type LoginProps = {
  setToken: (userInfo: UserInfo | null) => void;
};

const Login = ({ setToken }: LoginProps) => {
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInfo | null>(null);
  const [needs2FA, setNeeds2FA] = useState<boolean>(false);
  const [twoFACode, setTwoFACode] = useState("");
  const [playTextIndex, setPlayTextIndex] = useState<number>(-1);

  const toast = useToast();
  const history = useHistory();
  const textArray = ['iconoclasts.', 'pioneers.', 'producers.', 'startups.', 'merchants.']

  const handleSubmit = async (
    event:
      | React.FormEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    if (!userEmail || !password) return;
    try {
      setIsLoading(true);
      const userInfo = await authenticateUserWithCreds(userEmail, password);
      if (userInfo.require_2fa) {
        setNeeds2FA(true);
      }

      setCurrentUserInfo(userInfo);
    } catch (error) {
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = async (id: string) => {
    if (currentUserInfo && currentUserInfo.accounts) {
      var currentUserAccount = currentUserInfo.currentAccount;
      currentUserInfo.accounts.forEach((e) =>{
        if(e.id == id){
          currentUserAccount = e;
        }
      })
      if(currentUserAccount){
        const token = await getUserTokenByAccount(
          currentUserInfo.ticket,
          currentUserAccount.account
        );

        const newUserInfo: UserInfo = {
          ...currentUserInfo,
          currentAccount: currentUserAccount,
          token,
        };
        setToken(newUserInfo);

        history.push(`/segs/customer`);
        window.location.reload();
      }
    }
  };

  const handle2FAChange = async (inputCode: string) => {
    setTwoFACode(inputCode);
    // if(inputCode.length == 6){
    //   do2FASubmitWithCode(inputCode);
    // }
  };

  const handle2FASubmit = async (
    event:
      | React.FormEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    do2FASubmit();
  };

  const do2FASubmit = async () => {
    if (!twoFACode || twoFACode.length == 0 || !currentUserInfo?.token) return;
    do2FASubmitWithCode(twoFACode)
  };

  const do2FASubmitWithCode = async (code: string) => {
    if (!code || !currentUserInfo?.token) return;
    try {
      setIsLoading(true);
      const userInfo = await authenticateUserWith2FA(
        code,
        currentUserInfo.token
      );

      if (userInfo.accounts) {
        setNeeds2FA(false);
      }

      setCurrentUserInfo(userInfo);
    } catch (error) {
      toast({
        title: (error as Error).message,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const backToSignIn = async () => {
    setNeeds2FA(false)
    setTwoFACode("")
  };

  const handleAnimationStart = async () => {
    if(playTextIndex < textArray.length - 1){
      setPlayTextIndex(playTextIndex + 1)
    }else{
      setPlayTextIndex(0)
    }
  }

  return (
    <Flex
      minWidth={"600px"}
      flexDirection={"column"}
      px={20}
      pt={32}
    >
      <Text 
        fontSize={"61px"}
        fontFamily={"Beatrice-Regular"}
        color={"white"} 
        mb={2}
      >
        Offset Apps
      </Text>
      <Flex>
        <Text
          fontSize={"19px"}
          fontWeight={500}
          fontFamily={"Beatrice-Regular"}
          color={"white"}
          mr={1}
        >
          Design-driven technology for wine
        </Text>
        <animated.div
          style={useSpring({
            loop: true,
            from: { opacity: '0'},
            to: { opacity: '4'},
            config: { duration: 3000},
            onStart: () => handleAnimationStart(),
          })}
        >
          <Text
            fontSize={"19px"}
            fontWeight={500}
            fontFamily={"Beatrice-Regular"}
            color={"white"}
          >
            {playTextIndex >= 0 ? textArray[playTextIndex] : ""}
          </Text>
        </animated.div>
      </Flex>
      {needs2FA ? (
        <>
          <Text 
            mt={14}
            mb={1} 
            fontSize={"19px"} 
            fontWeight={500}
            fontFamily={"Beatrice-Regular"}
            color={"white"}>
              A six-digit code was just texted to you.
          </Text>
          <Flex flexDirection="column" w="410px">
            <CustomInput
              type="code"
              placeholder="Authentication Code"
              autoFocus={true}
              width={410}
              handleOnChange={(value) => handle2FAChange(value)}
            />
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              mt={3}
              fontSize={"20px"} 
              fontFamily={"Beatrice-Regular"}
              height={"48px"}
              disabled={twoFACode.length == 0}
              onClick={handle2FASubmit}
            >
              Continue
            </Button>
            <Link 
              mt={5}
              fontSize={"14px"} 
              color={"#F1EFEB"}
              onClick={() =>
                backToSignIn()
              }>
                Start Over
            </Link>
          </Flex>
        </>
      ) : (
        <>
          {currentUserInfo?.accounts ? (
            <>
              <Text 
                mt={14}
                mb={4} 
                fontSize={"19px"} 
                fontWeight={500}
                fontFamily={"Beatrice-Regular"}
                color={"white"}>
                  Please select an account to sign in
              </Text>
              <Flex flexDirection="column" w="410px">
                <Select
                  placeholder="Select an account"
                  variant="offset"
                  height={"60px"}
                  mb={6}
                  fontSize={"14px"}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  {Object.values(currentUserInfo.accounts).map((a) => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </Flex>
            </>
          ) : (
            <>
              <Flex mt={14} flexDirection="column" w="410px">
                <Text 
                  mb={1} 
                  fontSize={"19px"} 
                  fontWeight={500}
                  fontFamily={"Beatrice-Regular"}
                  color={"white"}>
                    Please Sign In
                </Text>
                <CustomInput
                  type="email"
                  placeholder="Email"
                  autoFocus={true}
                  width={410}
                  handleOnChange={(value) => setUserEmail(value)}
                  handleOnKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                />
                <CustomInput
                  type="password"
                  placeholder="Password"
                  autoFocus={false}
                  width={410}
                  handleOnChange={(value) => setPassword(value)}
                  handleOnKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }} 
                />
                <Button
                  isLoading={isLoading}
                  colorScheme="teal"
                  mt={3}
                  fontSize={"20px"} 
                  fontFamily={"Beatrice-Regular"}
                  height={"48px"}
                  onClick={handleSubmit}
                  // disabled={currentUserInfo?.accounts !== null}
                >
                  Sign In
                </Button>
                <Link 
                  fontSize={"14px"} 
                  color={"#F1EFEB"}
                  as={RouterDomLink}
                  to={`/forgot`}
                  mt={5}>
                    Forgot your password?
                </Link>
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;