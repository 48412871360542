/* eslint func-names: 0, no-console: 0 */
import React from "react";
import RcSelect from "rc-select";
import RcPagination, { PaginationProps } from "rc-pagination";
import localeInfo from "./en_US";
import "./select.css";
import "./pagination.css";

const textItemRender = (current: number, type: string, element: any) => {
  if (type === "prev") {
    return "Prev";
  }
  if (type === "next") {
    return "Next";
  }
  return element;
};

const ellipsis = <span className={`rc-pagination-item-ellipsis`}>•••</span>;
const jumpIcon = (
  <div className={`rc-pagination-item-link`}>
    <div className={`rc-pagination-item-container`}>{ellipsis}</div>
  </div>
);

const Pagination = (props: PaginationProps) => {
  return (
    <RcPagination
      selectComponentClass={RcSelect}
      showSizeChanger
      defaultPageSize={10}
      defaultCurrent={1}
      locale={localeInfo}
      itemRender={textItemRender}
      jumpPrevIcon={jumpIcon}
      jumpNextIcon={jumpIcon}
      pageSizeOptions={['10', '20', '50', '100', '200']}
      {...props}
    />
  );
};

export default Pagination;
