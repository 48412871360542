import { AxiosResponse } from "axios";
import { RuleRowType } from "../components/QueryBuilder/RuleRow";
import { http } from "./http";

export type Product = {
  id: string;
  remote_id: string;
  name: string;
  price: number;
  description: string;
  status: string;
  quantity: number;
  wine_type: string;
  varietal: string;
  sku: string;
  bottle_size: string;
  vintage: string;
  country: string;
  region: string;
  account_id: string;
};

export type ProductResData = {
  data: Product[];
  total: number;
  pageIndex: number;
  pageSize: number;
  sortIndex: number;
  sortType: number;
  isExport?: boolean;
};

export type ProductSimpleData = {
  simpleData: Product[];
  total: number;
};

export async function getProductById(id: string): Promise<Product> {
  try {
    const { data } = await http.get<Product>(`/api/v1/products/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
}

// These services use our own IL api
export async function getProductsByRuleGroups(
  rg: RuleRowType[][],
  accountId?: string,
  page: {
    pageSize: number;
    pageIndex: number;
  } = {
    pageSize: 50,
    pageIndex: 1,
  },
  sortIndex?: number,
  sortType?: number,
  isExport: boolean = false
): Promise<ProductResData> {
  try {
    const { data } = await http.post<
    {
      ruleGroups: RuleRowType[][];
      isExport?: boolean;
    },
    AxiosResponse<ProductResData>
  >(`/api/v1/products/filter/${accountId}/${sortIndex ?? 0}/${sortType ?? 0}`, {
    ruleGroups: rg,
    ...page,
    isExport,
  });

    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSimpleProducts(accountId?: string): Promise<ProductSimpleData> {
  try {
    const { data } = await http.get<ProductSimpleData>(`/api/v1/products/simple-products/${accountId}`);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function pullProductsFromOffset(
  accountId?: string
): Promise<void> {
  try {
    await http.get<Product[]>(
      `/api/v1/products/api-remote/sync-products${
        accountId ? `?account_id=${accountId}` : ""
      }`
    );
  } catch (error) {
    throw error;
  }
}
